import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Footer from "components/footer";
import Header from "components/header";
import HotelAdditionalInfoSection from "components/hotel_additional_info_section";
import HotelFacilitiesSection from "components/hotel_facilities_section";
import HotelInfoSection from "components/hotel_info_section";
import HotelRatesSection from "components/hotel_rates_section";
import Loading from "components/loading";
import MapSection from "components/map_section";
import PhotoGallery from "components/photo_gallery";
import SearchSection from "components/search_section";

import {
  AppActionsContext,
  BookingActionsContext,
  BookingDataContext,
} from "containers/data_context";

import setUrlParams from "utils/set_url_params";

import styles from "./hotel_page.module.css";

export default function HotelPage() {
  const { property, hotelDescriptions, roomTypeDescriptions, channelId, params } =
    useContext(BookingDataContext);
  const bookingActions = useContext(BookingActionsContext);
  const { init } = useContext(AppActionsContext);
  const { i18n } = useTranslation();
  const history = useHistory();
  const { data: propertyData, isLoading } = property;
  const { data: hotelDescriptionsData } = hotelDescriptions;
  const { data: roomTypeDescriptionsData } = roomTypeDescriptions;
  const isPropertyPresent = propertyData && !isLoading;
  const { currency, checkinDate, checkoutDate } = params;
  const [hotelDescription, setHotelDescription] = useState("");
  const [_roomTypeDescriptions, setRoomTypeDescriptions] = useState([]);

  useEffect(
    function initApp() {
      init(bookingActions);
    },
    [bookingActions, init],
  );

  useEffect(
    function initDescriptions() {
      const localeKey = i18n.language;

      const hotelDescriptionByLanguage =
        hotelDescriptionsData?.filter((obj) => obj.languageCode === localeKey).length > 0
          ? hotelDescriptionsData
              ?.filter((obj) => obj.languageCode === localeKey)
              ?.map((obj) => obj.content)
          : hotelDescriptionsData?.filter((obj) => obj.defaultLanguage)?.map((obj) => obj.content);
      setHotelDescription(hotelDescriptionByLanguage);

      const roomTypeDescriptionsByLanguage =
        roomTypeDescriptionsData?.filter((obj) => obj.languageCode === localeKey).length > 0
          ? roomTypeDescriptionsData
              ?.filter((obj) => obj.languageCode === localeKey)
              .map((obj) => {
                return {
                  id: obj.roomTypeId,
                  title: obj.roomTypeName,
                  description: obj.content,
                };
              })
          : roomTypeDescriptionsData
              ?.filter((obj) => obj.defaultLanguage)
              ?.map((obj) => {
                return {
                  id: obj.roomTypeId,
                  title: obj.roomTypeName,
                  description: obj.content,
                };
              });
      setRoomTypeDescriptions(roomTypeDescriptionsByLanguage);
    },
    [i18n, hotelDescriptions, roomTypeDescriptions],
  );

  const handleLocaleSelect = useCallback(
    function handleChange(localeKey) {
      i18n.changeLanguage(localeKey);
      moment.locale(localeKey);
      setUrlParams({ lang: localeKey }, history);

      const hotelDescriptionByLanguage =
        hotelDescriptionsData?.filter((obj) => obj.languageCode === localeKey).length > 0
          ? hotelDescriptionsData
              ?.filter((obj) => obj.languageCode === localeKey)
              ?.map((obj) => obj.content)
          : hotelDescriptionsData?.filter((obj) => obj.defaultLanguage)?.map((obj) => obj.content);
      setHotelDescription(hotelDescriptionByLanguage);

      const roomTypeDescriptionsByLanguage =
        roomTypeDescriptionsData?.filter((obj) => obj.languageCode === localeKey).length > 0
          ? roomTypeDescriptionsData
              ?.filter((obj) => obj.languageCode === localeKey)
              .map((obj) => {
                return {
                  id: obj.roomTypeId,
                  title: obj.roomTypeName,
                  description: obj.content,
                };
              })
          : roomTypeDescriptionsData
              ?.filter((obj) => obj.defaultLanguage)
              ?.map((obj) => {
                return {
                  id: obj.roomTypeId,
                  title: obj.roomTypeName,
                  description: obj.content,
                };
              });
      setRoomTypeDescriptions(roomTypeDescriptionsByLanguage);
    },
    [i18n, history, hotelDescriptions, roomTypeDescriptions],
  );

  useEffect(
    function loadClosedDates() {
      bookingActions.loadClosedDates(channelId);
    },
    [channelId, bookingActions],
  );

  useEffect(
    function loadBestOffer() {
      if (!currency && !channelId) {
        return;
      }

      const requestParams = {
        currency,
        checkinDate,
        checkoutDate,
      };

      bookingActions.loadBestOffer(channelId, requestParams);
    },
    [bookingActions, channelId, currency, checkinDate, checkoutDate],
  );

  if (!isPropertyPresent) {
    return <Loading />;
  }

  const isPhotosPresent = Boolean(propertyData.photos.length);

  return (
    <div>
      <Header property={propertyData} handleLocaleSelect={handleLocaleSelect} />
      {isPhotosPresent && <PhotoGallery photos={propertyData.photos} />}

      <div className={styles.floatingSearchContainer}>
        <SearchSection property={propertyData} />
        <HotelInfoSection property={propertyData} hotelDescription={hotelDescription} />
        <HotelRatesSection
          property={propertyData}
          roomTypeDescriptions={_roomTypeDescriptions}
          loading={isLoading}
        />
        <HotelFacilitiesSection property={propertyData} />
        <HotelAdditionalInfoSection property={propertyData} />
      </div>
      <MapSection property={propertyData} />
      <Footer property={propertyData} />
    </div>
  );
}
