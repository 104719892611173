import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import CurrencySelect from "components/inputs/currency_select";
import LocaleSelect from "components/inputs/locale_select";

import routes from "routing/routes";

import getPropertyName from "utils/get_property_name";

import HotelLogo from "./hotel_logo";
import HotelTitle from "./hotel_title";

import styles from "./header.module.css";

export default function Header({ property = {}, handleLocaleSelect }) {
  const [hotelLink, setHotelLink] = useState(null);
  const matchHotelPage = useRouteMatch({
    path: routes.hotelPage,
    strict: true,
  });

  const matchNotFoundPage = useRouteMatch({
    path: routes.default,
    strict: true,
  });

  const isCurrencySelectShown = matchHotelPage?.isExact && !matchNotFoundPage;
  const { title, logo, hideLogo, hideTitle } = property;

  useEffect(() => {
    const propertyName = getPropertyName();
    setHotelLink(`https://${propertyName}.com`);
  }, []);

  return (
    <div className={styles.header}>
      <div className={styles.titleSection}>
        <HotelLogo logo={logo} title={title} hideLogo={hideLogo} hotelLink={hotelLink} />
        <HotelTitle title={title} hideTitle={hideTitle} />
      </div>
      <div className={styles.selectSection}>
        {isCurrencySelectShown && <CurrencySelect />}
        <LocaleSelect handleLocaleSelect={handleLocaleSelect} />
      </div>
    </div>
  );
}
