/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
import actions from "api_actions/actions";

import getPropertyName from "./get_property_name";

async function getChannelIdFromApi(propertyName) {
  const resp = await actions.getChannexPropertyChannelId(propertyName);
  return resp.propertyId;
}

const getChannelId = () => {
  const propertyName = getPropertyName();

  if (!propertyName) {
    return null;
  }

  // if (propertyName == "vidavi") return "f8fa1930-48ff-46c2-97b0-689cd7a113cd";
  // if (propertyName == "saashotel") return "44b11d2a-5f2c-4cf5-bc71-0b109ac77b72";

  const channelId = getChannelIdFromApi(propertyName);
  return channelId;
};

export default getChannelId;
