/* eslint-disable eqeqeq */
import actions from "api_actions/actions";
import CryptoJS from "crypto-js";

const devMode = process.env.REACT_APP_ENV_MODE === "dev";

const sharedKey = devMode
  ? process.env.REACT_APP_SHUTTLE_SANDBOX_SHARED_KEY
  : process.env.REACT_APP_SHUTTLE_LIVE_SHARED_KEY;
const secretKey = devMode
  ? process.env.REACT_APP_SHUTTLE_SANDBOX_SECRET_KEY
  : process.env.REACT_APP_SHUTTLE_LIVE_SECRET_KEY;

export default {
  isActive: async (propertyName) => {
    const resp = await actions.getShuttleIsActive(propertyName);
    return resp.isActive;
  },

  instanceKey: async (propertyName) => {
    const resp = await actions.getShuttleInstanceKey(propertyName);
    return resp.instanceId;
  },

  generateSignature: (options) => {
    const timestamp = new Date().getTime();

    const signatureBody = JSON.stringify(
      Object.keys(options).reduce((o, k) => {
        if (k != "host" && k != "nonce") o[k] = options[k];
        return o;
      }, {}),
    );

    return `${sharedKey}-${timestamp}-${CryptoJS.MD5(
      `${timestamp}doPayment${signatureBody}${secretKey}`,
    )}`;
  },

  generatePayment: (options) => {
    return btoa(JSON.stringify(options));
  },

  bind: () => {
    const script = document.createElement("script");
    script.id = "shuttleBind";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = "setTimeout(() => {Shuttle.bind();}, 500)";
    document.body.appendChild(script);
  },

  doPayment: (options, signature) => {
    if (document.querySelector("#shuttlePayment") !== null) {
      document.querySelector("#shuttlePayment").remove();
    }

    const script = document.createElement("script");
    script.id = "shuttlePayment";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `Shuttle.doPayment(${JSON.stringify(options)}, '${signature}');`;
    document.body.appendChild(script);
  },
};
